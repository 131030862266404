import {
  __publicField
} from "./chunk-JCO6WRHX.js";

// src/components/Form/InputPassword/index.tsx
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons/faEyeSlash";

// src/components/Form/InputPassword/index.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
.input-password span {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/Form/InputPassword/index.tsx
var InputPassword = class extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "input");
    __publicField(this, "handleShowPassword", () => {
      if (this.state.showPassword) {
        this.input.current.setAttribute("type", "text");
      } else {
        this.input.current.setAttribute("type", "password");
      }
      this.setState((prevstate) => ({
        showPassword: !prevstate.showPassword
      }));
    });
    this.input = React.createRef();
    this.state = {
      showPassword: true
    };
  }
  render() {
    const { name, id, disabled, className, value, onChange, onKeyPress, onBlur } = this.props;
    return /* @__PURE__ */ React.createElement("div", { className: "position-relative input-password" }, /* @__PURE__ */ React.createElement(
      "input",
      {
        id,
        disabled,
        type: "password",
        className,
        value,
        name,
        onChange,
        onKeyPress,
        onBlur,
        ref: this.input
      }
    ), /* @__PURE__ */ React.createElement("span", { onClick: this.handleShowPassword, className: "cursor-pointer" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: this.state.showPassword ? faEye : faEyeSlash })));
  }
};

export {
  InputPassword
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

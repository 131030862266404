import {
  BI_ATTRIBUTE_FIELD_KEY,
  BI_BROWSERS_FIELD_KEY,
  BI_CHANNEL_FIELD_KEY,
  BI_CITIES_FIELD_KEY,
  BI_CONSENTS_DATE_FIELD_KEY,
  BI_CONSENTS_LIST_FIELD_KEY,
  BI_CONSENTS_TIER_FIELD_KEY,
  BI_COUNTRIES_FIELD_KEY,
  BI_DEVICES_FIELD_KEY,
  BI_DOMAIN_FIELD_KEY,
  BI_EVENTS_FIELD_KEY,
  BI_EVENTS_TYPE_FIELD_KEY,
  BI_FLOW_DETAIL_KEY,
  BI_FLOW_LIST_FIELD_KEY,
  BI_ISPS_FIELD_KEY,
  BI_LANGUAGES_FIELD_KEY,
  BI_LIVE_VISITORS_TOTAL_FIELD_KEY,
  BI_OUTLINK_FIELD_KEY,
  BI_PAGES_FIELD_KEY,
  BI_REFERER_FIELD_KEY,
  BI_REGION_FIELD_KEY,
  BI_SUMMARY_FIELD_KEY,
  BI_USER_FLOW_FIELD_KEY,
  BI_VISITORS_FIELD_KEY,
  BI_VISITOR_FIELD_KEY,
  BI_VISITS_FIELD_KEY,
  BI_WOOCOMMERCE_PRODUCT_CHART_FIELD_KEY,
  BI_WOOCOMMERCE_PRODUCT_FIELD_KEY,
  BI_WOOCOMMERCE_STATISTIC_CHART_FIELD_KEY,
  BI_WOOCOMMERCE_STATISTIC_FIELD_KEY
} from "./chunk-CEYGJUX4.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/Bi/BiModel.ts
var DomainModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.result.map((element) => {
        return new DomainItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var DomainItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.name = null;
    this.domain = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_DOMAIN_FIELD_KEY.NAME]: this.name,
        [BI_DOMAIN_FIELD_KEY.DOMAIN]: this.domain
      };
    };
    if (entity) {
      this.name = entity[BI_DOMAIN_FIELD_KEY.NAME] ?? "";
      this.domain = entity[BI_DOMAIN_FIELD_KEY.DOMAIN] ?? "";
    }
  }
};
var VisitorsModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new VisitorsItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var VisitorsItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.visits = null;
    this.date = null;
    this.total_page_views = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_VISITORS_FIELD_KEY.VISITS]: this.visits,
        [BI_VISITORS_FIELD_KEY.DATE]: this.date,
        [BI_VISITORS_FIELD_KEY.TOTAL_PAGE_VIEWS]: this.total_page_views
      };
    };
    if (entity) {
      this.visits = entity[BI_VISITORS_FIELD_KEY.VISITS] ?? "";
      this.date = entity[BI_VISITORS_FIELD_KEY.DATE] ?? "";
      this.total_page_views = entity[BI_VISITORS_FIELD_KEY.TOTAL_PAGE_VIEWS] ?? "";
    }
  }
};
var SummaryModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new SummaryItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var VisitsModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new VisitsItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var VisitsItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.visits = null;
    this.date = null;
    this.unique_visits = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_VISITS_FIELD_KEY.VISITS]: this.visits,
        [BI_VISITS_FIELD_KEY.DATE]: this.date,
        [BI_VISITS_FIELD_KEY.UNIQUE_VISITS]: this.unique_visits
      };
    };
    if (entity) {
      this.visits = entity[BI_VISITS_FIELD_KEY.VISITS] ?? "";
      this.date = entity[BI_VISITORS_FIELD_KEY.DATE] ?? "";
      this.unique_visits = entity[BI_VISITS_FIELD_KEY.UNIQUE_VISITS] ?? "";
    }
  }
};
var SummaryItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.number_of_visitors = null;
    this.total_number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.total_number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.TOTAL_NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var MetricsModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.number_of_visitors = null;
    this.total_number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.TOTAL_NUMBER_OF_VISITORS]: this.total_number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.total_number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.TOTAL_NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var VisitorModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new VisitorItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var VisitorItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.start_date = null;
    this.end_date = null;
    this.event_name = null;
    this.event_type = null;
    this.attributes = null;
    this.url = null;
    this.referer = null;
    this.flow_id = null;
    this.uuid = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_VISITOR_FIELD_KEY.EVENT_NAME]: this.event_name,
        [BI_VISITOR_FIELD_KEY.EVENT_TYPE]: this.event_type,
        [BI_VISITOR_FIELD_KEY.START_DATE]: this.start_date,
        [BI_VISITOR_FIELD_KEY.END_DATE]: this.end_date,
        [BI_VISITOR_FIELD_KEY.ATTRIBUTES]: this.attributes,
        [BI_VISITOR_FIELD_KEY.URL]: this.url,
        [BI_VISITOR_FIELD_KEY.REFERER]: this.referer,
        [BI_VISITOR_FIELD_KEY.FLOW_ID]: this.flow_id,
        [BI_VISITOR_FIELD_KEY.UUID]: this.uuid
      };
    };
    if (entity) {
      this.start_date = entity[BI_VISITOR_FIELD_KEY.START_DATE] ?? "";
      this.end_date = entity[BI_VISITOR_FIELD_KEY.END_DATE] ?? "";
      this.event_name = entity[BI_VISITOR_FIELD_KEY.EVENT_NAME] ?? "";
      this.event_type = entity[BI_VISITOR_FIELD_KEY.EVENT_TYPE] ?? "";
      this.attributes = entity[BI_VISITOR_FIELD_KEY.ATTRIBUTES] ?? [];
      this.url = entity[BI_VISITOR_FIELD_KEY.URL] ?? "";
      this.referer = entity[BI_VISITOR_FIELD_KEY.REFERER] ?? "";
      this.flow_id = entity[BI_VISITOR_FIELD_KEY.FLOW_ID] ?? "";
      this.uuid = entity[BI_VISITOR_FIELD_KEY.UUID] ?? "";
    }
  }
};
var FlowItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.uuid = null;
    this.ip = null;
    this.user_agent = null;
    this.device = null;
    this.browser_name = null;
    this.browser_version = null;
    this.domain = null;
    this.lang = null;
    this.start = null;
    this.end = null;
    this.geo = null;
    this.events = [];
    this.event = null;
    this.conversion = null;
    this.action = null;
    this.duration = null;
    this.ux_percent = null;
    this.pageview = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_FLOW_DETAIL_KEY.UUID]: this.uuid,
        [BI_FLOW_DETAIL_KEY.IP]: this.ip,
        [BI_FLOW_DETAIL_KEY.USER_AGENT]: this.user_agent,
        [BI_FLOW_DETAIL_KEY.DEVICE]: this.device,
        [BI_FLOW_DETAIL_KEY.BROWSER_NAME]: this.browser_name,
        [BI_FLOW_DETAIL_KEY.DOMAIN]: this.domain,
        [BI_FLOW_DETAIL_KEY.LANG]: this.lang,
        [BI_FLOW_DETAIL_KEY.START]: this.start,
        [BI_FLOW_DETAIL_KEY.END]: this.end,
        [BI_FLOW_DETAIL_KEY.GEO]: this.geo,
        [BI_FLOW_DETAIL_KEY.EVENTS]: this.events,
        [BI_FLOW_DETAIL_KEY.EVENT]: this.event,
        [BI_FLOW_DETAIL_KEY.CONVERSION]: this.conversion,
        [BI_FLOW_DETAIL_KEY.ACTION]: this.action,
        [BI_FLOW_DETAIL_KEY.DURATION]: this.duration,
        [BI_FLOW_DETAIL_KEY.UX_PERCENT]: this.ux_percent,
        [BI_FLOW_DETAIL_KEY.PAGE_VIEW]: this.pageview
      };
    };
    if (entity) {
      this.uuid = entity[BI_FLOW_DETAIL_KEY.UUID] ?? "";
      this.ip = entity[BI_FLOW_DETAIL_KEY.IP] ?? "";
      this.user_agent = entity[BI_FLOW_DETAIL_KEY.USER_AGENT] ?? "";
      this.device = entity[BI_FLOW_DETAIL_KEY.DEVICE] ?? "";
      this.browser_name = entity[BI_FLOW_DETAIL_KEY.BROWSER_NAME] ?? "";
      this.domain = entity[BI_FLOW_DETAIL_KEY.DOMAIN] ?? "";
      this.lang = entity[BI_FLOW_DETAIL_KEY.LANG] ?? "";
      this.start = entity[BI_FLOW_DETAIL_KEY.START] ?? "";
      this.end = entity[BI_FLOW_DETAIL_KEY.END] ?? "";
      this.geo = entity[BI_FLOW_DETAIL_KEY.GEO] ?? "";
      this.events = entity[BI_FLOW_DETAIL_KEY.EVENTS] ?? [];
      this.event = entity[BI_FLOW_DETAIL_KEY.EVENT] ?? 0;
      this.conversion = entity[BI_FLOW_DETAIL_KEY.CONVERSION] ?? 0;
      this.action = entity[BI_FLOW_DETAIL_KEY.ACTION] ?? 0;
      this.duration = entity[BI_FLOW_DETAIL_KEY.DURATION] ?? 0;
      this.ux_percent = entity[BI_FLOW_DETAIL_KEY.UX_PERCENT] ?? 0;
      this.pageview = entity[BI_FLOW_DETAIL_KEY.PAGE_VIEW] ?? 0;
    }
  }
};
var DevicesModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new DevicesItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var DevicesItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.device = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_DEVICES_FIELD_KEY.DEVICE]: this.device,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.device = entity[BI_DEVICES_FIELD_KEY.DEVICE] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var CountriesModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new CountriesItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var CountriesItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.country_name = null;
    this.country_code = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_COUNTRIES_FIELD_KEY.COUNTRY_NAME]: this.country_name,
        [BI_COUNTRIES_FIELD_KEY.COUNTRY_CODE]: this.country_code,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.country_name = entity[BI_COUNTRIES_FIELD_KEY.COUNTRY_NAME] ?? "";
      this.country_code = entity[BI_COUNTRIES_FIELD_KEY.COUNTRY_CODE] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var CitiesModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new CitiesItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var CitiesItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.city = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_CITIES_FIELD_KEY.CITY]: this.city,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.city = entity[BI_CITIES_FIELD_KEY.CITY] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var IspsItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.isps = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_ISPS_FIELD_KEY.ISP]: this.isps,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.isps = entity[BI_ISPS_FIELD_KEY.ISP] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var BrowsersModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new BrowsersItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var BrowsersItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.browser_name = null;
    this.number_of_visitors = null;
    this.number_of_visitors_percent = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_BROWSERS_FIELD_KEY.BROWSER_NAME]: this.browser_name,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS_PERCENT]: this.number_of_visitors_percent,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.browser_name = entity[BI_BROWSERS_FIELD_KEY.BROWSER_NAME] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_visitors_percent = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS_PERCENT] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var IspsModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new IspsItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var LanguagesModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new LanguagesItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var LanguagesItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.lang = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_LANGUAGES_FIELD_KEY.LANG]: this.lang,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.lang = entity[BI_LANGUAGES_FIELD_KEY.LANG] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var PagesModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new PagesItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var PagesItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.url = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_PAGES_FIELD_KEY.URL]: this.url,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.url = entity[BI_PAGES_FIELD_KEY.URL] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var EventsModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new EventsItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var EventsItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.event_name = null;
    this.event_type = null;
    this.total_visitor = null;
    this.date = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_EVENTS_FIELD_KEY.EVENT_NAME]: this.event_name,
        [BI_EVENTS_FIELD_KEY.EVENT_TYPE]: this.event_type,
        [BI_EVENTS_FIELD_KEY.TOTAL_VISITOR]: this.total_visitor,
        [BI_EVENTS_FIELD_KEY.DATE]: this.date
      };
    };
    if (entity) {
      this.event_name = entity[BI_EVENTS_FIELD_KEY.EVENT_NAME] ?? "";
      this.event_type = entity[BI_EVENTS_FIELD_KEY.EVENT_TYPE] ?? "";
      this.total_visitor = entity[BI_EVENTS_FIELD_KEY.TOTAL_VISITOR] ?? "";
      this.date = entity[BI_EVENTS_FIELD_KEY.DATE] ?? "";
    }
  }
};
var WoocommerceStatisticModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new WoocommerceStatisticItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var WoocommerceStatisticItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.avg_order_value = null;
    this.conversion_rate = null;
    this.total_add_to_carts = null;
    this.total_revenue = null;
    this.transactions = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_WOOCOMMERCE_STATISTIC_FIELD_KEY.AVG_ORDER_VALUE]: this.avg_order_value,
        [BI_WOOCOMMERCE_STATISTIC_FIELD_KEY.CONVERSION_RATE]: this.conversion_rate,
        [BI_WOOCOMMERCE_STATISTIC_FIELD_KEY.TOTAL_ADD_TO_CARTS]: this.total_add_to_carts,
        [BI_WOOCOMMERCE_STATISTIC_FIELD_KEY.TOTAL_REVENUE]: this.total_revenue,
        [BI_WOOCOMMERCE_STATISTIC_FIELD_KEY.TRANSACTIONS]: this.transactions
      };
    };
    if (entity) {
      this.avg_order_value = entity[BI_WOOCOMMERCE_STATISTIC_FIELD_KEY.AVG_ORDER_VALUE] ?? "";
      this.conversion_rate = entity[BI_WOOCOMMERCE_STATISTIC_FIELD_KEY.CONVERSION_RATE] ?? "";
      this.total_add_to_carts = entity[BI_WOOCOMMERCE_STATISTIC_FIELD_KEY.TOTAL_ADD_TO_CARTS] ?? "";
      this.total_revenue = entity[BI_WOOCOMMERCE_STATISTIC_FIELD_KEY.TOTAL_REVENUE] ?? "";
      this.transactions = entity[BI_WOOCOMMERCE_STATISTIC_FIELD_KEY.TRANSACTIONS] ?? "";
    }
  }
};
var WoocommerceStatisticChartModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new WoocommerceStatisticChartItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var WoocommerceStatisticChartItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.date = null;
    this.total_purchasers = null;
    this.total_revenue = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_WOOCOMMERCE_STATISTIC_CHART_FIELD_KEY.DATE]: this.date,
        [BI_WOOCOMMERCE_STATISTIC_CHART_FIELD_KEY.TOTAL_PURCHASERS]: this.total_purchasers,
        [BI_WOOCOMMERCE_STATISTIC_CHART_FIELD_KEY.TOTAL_REVENUE]: this.total_revenue
      };
    };
    if (entity) {
      this.date = entity[BI_WOOCOMMERCE_STATISTIC_CHART_FIELD_KEY.DATE] ?? "";
      this.total_purchasers = entity[BI_WOOCOMMERCE_STATISTIC_CHART_FIELD_KEY.TOTAL_PURCHASERS] ?? "";
      this.total_revenue = entity[BI_WOOCOMMERCE_STATISTIC_CHART_FIELD_KEY.TOTAL_REVENUE] ?? "";
    }
  }
};
var WoocommerceProductChartModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new WoocommerceProductChartItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var WoocommerceProductChartItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.date = null;
    this.quantity = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_WOOCOMMERCE_PRODUCT_CHART_FIELD_KEY.DATE]: this.date,
        [BI_WOOCOMMERCE_PRODUCT_CHART_FIELD_KEY.QUANTITY]: this.quantity
      };
    };
    if (entity) {
      this.date = entity[BI_WOOCOMMERCE_PRODUCT_CHART_FIELD_KEY.DATE] ?? "";
      this.quantity = entity[BI_WOOCOMMERCE_PRODUCT_CHART_FIELD_KEY.QUANTITY] ?? "";
    }
  }
};
var WoocommerceProductModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new WoocommerceProductItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var WoocommerceProductItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.avg_price = null;
    this.avg_quantity = null;
    this.items_sold = null;
    this.product = null;
    this.product_revenue = null;
    this.quantity = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.AVG_PRICE]: this.avg_price,
        [BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.AVG_QUANTITY]: this.avg_quantity,
        [BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.ITEMS_SOLD]: this.items_sold,
        [BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.PRODUCT]: this.product,
        [BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.PRODUCT_REVENUE]: this.product_revenue,
        [BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.QUANTITY]: this.quantity
      };
    };
    if (entity) {
      this.avg_price = entity[BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.AVG_PRICE] ?? "";
      this.avg_quantity = entity[BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.AVG_QUANTITY] ?? "";
      this.items_sold = entity[BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.ITEMS_SOLD] ?? "";
      this.product = entity[BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.PRODUCT] ?? "";
      this.product_revenue = entity[BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.PRODUCT_REVENUE] ?? "";
      this.quantity = entity[BI_WOOCOMMERCE_PRODUCT_FIELD_KEY.QUANTITY] ?? "";
    }
  }
};
var ConsentsListModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new ConsentsListItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var ConsentsListItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.consent = null;
    this.tier = null;
    this.datetime = null;
    this.expiration = null;
    this.uuid = null;
    this.wallet = null;
    this.web3id = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_CONSENTS_LIST_FIELD_KEY.CONSENT]: this.consent,
        [BI_CONSENTS_LIST_FIELD_KEY.TIER]: this.tier,
        [BI_CONSENTS_LIST_FIELD_KEY.DATETIME]: this.datetime,
        [BI_CONSENTS_LIST_FIELD_KEY.EXPIRATION]: this.expiration,
        [BI_CONSENTS_LIST_FIELD_KEY.UUID]: this.uuid,
        [BI_CONSENTS_LIST_FIELD_KEY.WALLET]: this.wallet,
        [BI_CONSENTS_LIST_FIELD_KEY.WEB3ID]: this.web3id
      };
    };
    if (entity) {
      this.consent = entity[BI_CONSENTS_LIST_FIELD_KEY.CONSENT] ?? "";
      this.tier = entity[BI_CONSENTS_LIST_FIELD_KEY.TIER] ?? "";
      this.datetime = entity[BI_CONSENTS_LIST_FIELD_KEY.DATETIME] ?? "";
      this.expiration = entity[BI_CONSENTS_LIST_FIELD_KEY.EXPIRATION] ?? "";
      this.uuid = entity[BI_CONSENTS_LIST_FIELD_KEY.UUID] ?? "";
      this.wallet = entity[BI_CONSENTS_LIST_FIELD_KEY.WALLET] ?? "";
      this.web3id = entity[BI_CONSENTS_LIST_FIELD_KEY.WEB3ID] ?? "";
    }
  }
};
var ConsentsDateModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new ConsentsDateItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var ConsentsDateItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.date = null;
    this.total = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_CONSENTS_DATE_FIELD_KEY.DATE]: this.date,
        [BI_CONSENTS_DATE_FIELD_KEY.TOTAL]: this.total
      };
    };
    if (entity) {
      this.date = entity[BI_CONSENTS_DATE_FIELD_KEY.DATE] ?? "";
      this.total = entity[BI_CONSENTS_DATE_FIELD_KEY.TOTAL] ?? "";
    }
  }
};
var ConsentsTierModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new ConsentsTierItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var ConsentsTierItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.tier = null;
    this.total = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_CONSENTS_TIER_FIELD_KEY.TIER]: this.tier,
        [BI_CONSENTS_TIER_FIELD_KEY.TOTAL]: this.total
      };
    };
    if (entity) {
      this.tier = entity[BI_CONSENTS_TIER_FIELD_KEY.TIER] ?? "";
      this.total = entity[BI_CONSENTS_TIER_FIELD_KEY.TOTAL] ?? "";
    }
  }
};
var RefererModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new RefererItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var RefererItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.number_of_visitors = null;
    this.referer = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_REFERER_FIELD_KEY.REFERER]: this.referer
      };
    };
    if (entity) {
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.referer = entity[BI_REFERER_FIELD_KEY.REFERER] ?? "";
    }
  }
};
var FlowListModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new FlowListItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var FlowListItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.uuid = null;
    this.flow_uuid = null;
    this.geo = null;
    this.start = null;
    this.end = null;
    this.url = null;
    this.referrer = null;
    this.sop_id = null;
    this.events = null;
    this.event = null;
    this.conversion = null;
    this.action = null;
    this.duration = null;
    this.device = null;
    this.pageview = null;
    this.bounce_rate = null;
    this.ux_percent = null;
    this.visit_actions = null;
    this.event_actions = null;
    this.conversion_actions = null;
    this.bad_user = null;
    this.traffic = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_FLOW_LIST_FIELD_KEY.UUID]: this.uuid,
        [BI_FLOW_LIST_FIELD_KEY.FLOW_UUID]: this.flow_uuid,
        [BI_FLOW_LIST_FIELD_KEY.GEO]: this.geo,
        [BI_FLOW_LIST_FIELD_KEY.START]: this.start,
        [BI_FLOW_LIST_FIELD_KEY.END]: this.end,
        [BI_FLOW_LIST_FIELD_KEY.URL]: this.url,
        [BI_FLOW_LIST_FIELD_KEY.REFERRER]: this.referrer,
        [BI_FLOW_LIST_FIELD_KEY.SOP_ID]: this.sop_id,
        [BI_FLOW_LIST_FIELD_KEY.EVENTS]: this.events,
        [BI_FLOW_LIST_FIELD_KEY.EVENT]: this.event,
        [BI_FLOW_LIST_FIELD_KEY.CONVERSION]: this.conversion,
        [BI_FLOW_LIST_FIELD_KEY.ACTION]: this.action,
        [BI_FLOW_LIST_FIELD_KEY.DURATION]: this.duration,
        [BI_FLOW_LIST_FIELD_KEY.DEVICE]: this.device,
        [BI_FLOW_LIST_FIELD_KEY.PAGEVIEW]: this.pageview,
        [BI_FLOW_LIST_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate,
        [BI_FLOW_LIST_FIELD_KEY.UX_PERCENT]: this.ux_percent,
        [BI_FLOW_LIST_FIELD_KEY.VISIT_ACTIONS]: this.visit_actions,
        [BI_FLOW_LIST_FIELD_KEY.EVENT_ACTIONS]: this.event_actions,
        [BI_FLOW_LIST_FIELD_KEY.CONVERSION_ACTIONS]: this.conversion_actions,
        [BI_FLOW_LIST_FIELD_KEY.TRAFFIC]: this.traffic
      };
    };
    if (entity) {
      this.uuid = entity[BI_FLOW_LIST_FIELD_KEY.UUID] ?? "";
      this.flow_uuid = entity[BI_FLOW_LIST_FIELD_KEY.FLOW_UUID] ?? "";
      this.geo = entity[BI_FLOW_LIST_FIELD_KEY.GEO] ?? "";
      this.start = entity[BI_FLOW_LIST_FIELD_KEY.START] ?? "";
      this.end = entity[BI_FLOW_LIST_FIELD_KEY.END] ?? "";
      this.url = entity[BI_FLOW_LIST_FIELD_KEY.EVENTS]?.length ? entity[BI_FLOW_LIST_FIELD_KEY.EVENTS][0]?.url : "";
      this.referrer = entity[BI_FLOW_LIST_FIELD_KEY.REFERRER] ?? "";
      this.events = entity[BI_FLOW_LIST_FIELD_KEY.EVENTS] ?? "";
      this.event = entity[BI_FLOW_LIST_FIELD_KEY.EVENT] ?? 0;
      this.conversion = entity[BI_FLOW_LIST_FIELD_KEY.CONVERSION] ?? 0;
      this.action = entity[BI_FLOW_LIST_FIELD_KEY.ACTION] ?? 0;
      this.duration = entity[BI_FLOW_LIST_FIELD_KEY.DURATION] ?? 0;
      this.device = entity[BI_FLOW_LIST_FIELD_KEY.DEVICE] ?? "";
      this.pageview = entity[BI_FLOW_LIST_FIELD_KEY.PAGEVIEW] ?? 0;
      this.bounce_rate = entity[BI_FLOW_LIST_FIELD_KEY.BOUNCE_RATE] ?? 0;
      this.ux_percent = entity[BI_FLOW_LIST_FIELD_KEY.UX_PERCENT] ?? 0;
      this.sop_id = entity[BI_FLOW_LIST_FIELD_KEY.SOP_ID] ?? "";
      this.visit_actions = entity[BI_FLOW_LIST_FIELD_KEY.VISIT_ACTIONS] ?? 0;
      this.event_actions = entity[BI_FLOW_LIST_FIELD_KEY.EVENT_ACTIONS] ?? 0;
      this.conversion_actions = entity[BI_FLOW_LIST_FIELD_KEY.CONVERSION_ACTIONS] ?? 0;
      this.bad_user = entity[BI_FLOW_LIST_FIELD_KEY.BAD_USER] ?? false;
      this.traffic = entity[BI_FLOW_LIST_FIELD_KEY.TRAFFIC] ?? {
        bad_user: entity[BI_FLOW_LIST_FIELD_KEY.BAD_USER] ?? false,
        device: entity[BI_FLOW_LIST_FIELD_KEY.DEVICE] ?? ""
      };
    }
  }
};
var FlowDateModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new FlowDateItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var FlowDateItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.date = null;
    this.event = null;
    this.conversion = null;
    this.action = null;
    this.pageview = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_FLOW_LIST_FIELD_KEY.DATE]: this.date,
        [BI_FLOW_LIST_FIELD_KEY.EVENT]: this.event,
        [BI_FLOW_LIST_FIELD_KEY.CONVERSION]: this.conversion,
        [BI_FLOW_LIST_FIELD_KEY.ACTION]: this.action,
        [BI_FLOW_LIST_FIELD_KEY.PAGEVIEW]: this.pageview
      };
    };
    if (entity) {
      this.date = entity[BI_FLOW_LIST_FIELD_KEY.DATE] ?? "";
      this.event = entity[BI_FLOW_LIST_FIELD_KEY.EVENT] ?? "";
      this.conversion = entity[BI_FLOW_LIST_FIELD_KEY.CONVERSION] ?? "";
      this.action = entity[BI_FLOW_LIST_FIELD_KEY.ACTION] ?? "";
      this.pageview = entity[BI_FLOW_LIST_FIELD_KEY.PAGEVIEW] ?? "";
    }
  }
};
var ChannelModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new ChannelItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var ChannelItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.channel = null;
    this.number_of_visitors = null;
    this.total_number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_CHANNEL_FIELD_KEY.CHANNEL]: this.channel,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.TOTAL_NUMBER_OF_VISITORS]: this.total_number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.channel = entity[BI_CHANNEL_FIELD_KEY.CHANNEL] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.total_number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.TOTAL_NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var OutlinkModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new OutlinkItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var OutlinkItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.referer = null;
    this.total_urls = null;
    this.urls = null;
    this.number_of_visitors = null;
    this.total_number_of_visitors = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_OUTLINK_FIELD_KEY.REFERER]: this.referer,
        [BI_OUTLINK_FIELD_KEY.TOTAL_URLS]: this.total_urls,
        [BI_OUTLINK_FIELD_KEY.URLS]: this.urls,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.TOTAL_NUMBER_OF_VISITORS]: this.total_number_of_visitors
      };
    };
    if (entity) {
      this.referer = entity[BI_OUTLINK_FIELD_KEY.REFERER] ?? "";
      this.total_urls = entity[BI_OUTLINK_FIELD_KEY.TOTAL_URLS] ?? "";
      this.urls = entity[BI_OUTLINK_FIELD_KEY.URLS] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.total_number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.TOTAL_NUMBER_OF_VISITORS] ?? "";
    }
  }
};
var EventsTypeModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new EventsTypeItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var EventsTypeItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.event_name = null;
    this.event_type = null;
    this.total_visitor = null;
    this.unique_visitor = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_EVENTS_TYPE_FIELD_KEY.EVENT_NAME]: this.event_name,
        [BI_EVENTS_TYPE_FIELD_KEY.EVENT_TYPE]: this.event_type,
        [BI_EVENTS_TYPE_FIELD_KEY.TOTAL_VISITOR]: this.total_visitor,
        [BI_EVENTS_TYPE_FIELD_KEY.UNIQUE_VISITOR]: this.unique_visitor
      };
    };
    if (entity) {
      this.event_name = entity[BI_EVENTS_TYPE_FIELD_KEY.EVENT_NAME] ?? "";
      this.event_type = entity[BI_EVENTS_TYPE_FIELD_KEY.EVENT_TYPE] ?? "";
      this.total_visitor = entity[BI_EVENTS_TYPE_FIELD_KEY.TOTAL_VISITOR] ?? "";
      this.unique_visitor = entity[BI_EVENTS_TYPE_FIELD_KEY.UNIQUE_VISITOR] ?? "";
    }
  }
};
var AttributeModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new AttributeItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var AttributeItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.name = null;
    this.values = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_ATTRIBUTE_FIELD_KEY.NAME]: this.name,
        [BI_ATTRIBUTE_FIELD_KEY.VALUES]: this.values
      };
    };
    if (entity) {
      this.name = entity[BI_ATTRIBUTE_FIELD_KEY.NAME] ?? "";
      this.values = entity[BI_ATTRIBUTE_FIELD_KEY.VALUES] ?? "";
    }
  }
};
var RegionModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new RegionItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var RegionItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.region = null;
    this.number_of_visitors = null;
    this.total_number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_REGION_FIELD_KEY.REGION]: this.region,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.TOTAL_NUMBER_OF_VISITORS]: this.total_number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.region = entity[BI_REGION_FIELD_KEY.REGION] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.total_number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.TOTAL_NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var UserFlowModel = class extends BaseModel_default {
  constructor(entity) {
    super(entity);
    this.nodes = null;
    this.links = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        [BI_USER_FLOW_FIELD_KEY.NODES]: this.nodes,
        [BI_USER_FLOW_FIELD_KEY.LINKS]: this.links
      };
    };
    if (entity) {
      this.nodes = entity[BI_USER_FLOW_FIELD_KEY.NODES] ?? "";
      this.links = entity[BI_USER_FLOW_FIELD_KEY.LINKS] ?? "";
    }
  }
};
var LiveVisitorsDeviceModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new LiveVisitorsDeviceItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var LiveVisitorsDeviceItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.device = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_DEVICES_FIELD_KEY.DEVICE]: this.device,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.device = entity[BI_DEVICES_FIELD_KEY.DEVICE] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var LiveVisitorsListModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new LiveVisitorsListItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var LiveVisitorsListItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.uuid = null;
    this.ip = null;
    this.geo = null;
    this.events = null;
    this.url = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_FLOW_DETAIL_KEY.UUID]: this.uuid,
        [BI_FLOW_DETAIL_KEY.IP]: this.ip,
        [BI_FLOW_DETAIL_KEY.GEO]: this.geo,
        [BI_FLOW_DETAIL_KEY.EVENTS]: this.events,
        [BI_FLOW_LIST_FIELD_KEY.URL]: this.url
      };
    };
    if (entity) {
      this.uuid = entity[BI_FLOW_DETAIL_KEY.UUID] ?? "";
      this.ip = entity[BI_FLOW_DETAIL_KEY.IP] ?? "";
      this.geo = entity[BI_FLOW_DETAIL_KEY.GEO] ?? "";
      this.events = entity[BI_FLOW_DETAIL_KEY.EVENTS] ?? "";
      this.url = entity[BI_FLOW_LIST_FIELD_KEY.URL] ?? "";
    }
  }
};
var LiveVisitorsTotalModel = class extends BaseModel_default {
  constructor(entity) {
    super(entity);
    this.total = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        [BI_LIVE_VISITORS_TOTAL_FIELD_KEY.TOTAL]: this.total
      };
    };
    if (entity) {
      this.total = entity[BI_LIVE_VISITORS_TOTAL_FIELD_KEY.TOTAL] ?? "";
    }
  }
};

export {
  DomainModel,
  VisitorsModel,
  SummaryModel,
  VisitsModel,
  SummaryItemModel,
  MetricsModel,
  VisitorModel,
  VisitorItemModel,
  FlowItemModel,
  DevicesModel,
  CountriesModel,
  CitiesModel,
  BrowsersModel,
  IspsModel,
  LanguagesModel,
  PagesModel,
  EventsModel,
  WoocommerceStatisticModel,
  WoocommerceStatisticChartModel,
  WoocommerceProductChartModel,
  WoocommerceProductModel,
  ConsentsListModel,
  ConsentsDateModel,
  ConsentsTierModel,
  RefererModel,
  FlowListModel,
  FlowDateModel,
  ChannelModel,
  OutlinkModel,
  EventsTypeModel,
  AttributeModel,
  RegionModel,
  UserFlowModel,
  LiveVisitorsDeviceModel,
  LiveVisitorsListModel,
  LiveVisitorsTotalModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  Instance_default
} from "./chunk-K7Z5Y6B4.js";
import {
  BaseRoute_default
} from "./chunk-Q7RR6V45.js";
import {
  env
} from "./chunk-JXRCNPEL.js";

// src/Bi/BiRoute.ts
var version = "/v1/";
var BiRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.getAttribute = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "attribute" + version,
            date: dateFilter,
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getAttributeDate = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "attribute_date" + version,
            date: dateFilter,
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getFlowDetail = (flowId, dataFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "flow" + version + flowId,
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getVisitor = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "visitor" + version,
            date: dateFilter,
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getVisitors = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "visitors" + version,
            date: dateFilter,
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getVisits = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "visits" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getMetrics = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "metrics" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getSummary = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "domains" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getDevices = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "devices" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getCountries = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "countries" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getCities = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "cities" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getBrowsers = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "browsers" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getLanguages = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "languages" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getPages = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "pages" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getEvents = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "events" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getWoocommerceProduct = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "conversion" + version + "products/",
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getWoocommerceProductChart = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "conversion" + version + "products-chart/",
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getWoocommerceStatistic = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "conversion" + version + "statistics/",
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getWoocommerceStatisticChart = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "conversion" + version + "statistics-chart/",
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getConsentsList = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "consents" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getConsentsDate = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "consents" + version + dateFilter?.date_start + "/" + dateFilter?.date_end + "/date",
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getConsentsTier = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "consents" + version + dateFilter?.date_start + "/" + dateFilter?.date_end + "/tier",
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getConsentsTemplate = (domain) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: `datastream/template/${domain}`
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL ?? "https://api.analytics.aesirx.io",
          true
        )
      );
    };
    this.updateConsentsTemplate = (data) => {
      return Instance_default.post(
        this.createRequestURL(
          {
            url: `datastream/template`
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL ?? "https://api.analytics.aesirx.io",
          true
        ),
        data
      );
    };
    this.getReferer = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "referrers" + version + dateFilter?.date_start + "/" + dateFilter?.date_end,
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getFlowList = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "flow" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getFlowDate = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "flow" + version + dateFilter?.date_start + "/" + dateFilter?.date_end + "/date",
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getIsps = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "isps" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getChannel = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "channels" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getOutlink = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "outlinks" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getEventsType = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "events_name_type" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getRegion = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "regions" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getUserFlow = (dataFilter, dateFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "user_flows" + version,
            filter: dataFilter,
            date: dateFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getLiveVisitorsDevice = (dataFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "live_visitors/device",
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getLiveVisitorsList = (dataFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "live_visitors/list",
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.getLiveVisitorsTotal = (dataFilter) => {
      return Instance_default.get(
        this.createRequestURL(
          {
            url: "live_visitors/total",
            filter: dataFilter
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        )
      );
    };
    this.init = () => {
      return Instance_default.post(
        this.createRequestURL(
          {
            url: "visitor" + version + "init"
          },
          false,
          env.REACT_APP_BI_ENDPOINT_URL,
          true
        ),
        {
          ...{
            ip: "1.1.1.1",
            user_agent: "Mozilla/5.0",
            device: "iPhone 13",
            browser_name: "Firefox",
            browser_version: "75",
            domain: "aesirx.io",
            lang: "en-GB",
            url: "https://aesirx.io",
            referer: "https://r-digital.tech",
            event_name: "visit",
            event_type: "action",
            attributes: [
              {
                name: "form_id",
                value: "xxx"
              }
            ]
          }
        }
      );
    };
  }
};
var BiRoute_default = BiRoute;

export {
  BiRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  SVGComponent
} from "./chunk-C5UZGLWY.js";
import {
  ModalDAMComponent
} from "./chunk-25MFLZ6O.js";
import {
  Image
} from "./chunk-2MXWHUGP.js";

// src/components/Form/FormImage/index.tsx
import React, { useState } from "react";
import { Button, Col, Ratio, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";

// src/components/Form/FormImage/index.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
.field-single-image {
  width: 248px;
  height: 248px;
  outline: 0;
}

.image-wrapper:hover .delete-icon {
  display: block;
}

.delete-icon {
  display: none;
  position: absolute;
  top: 13px;
  right: 13px;
}
.delete-icon:hover {
  background-color: #ebebeb;
}

.field-multi-image .delete-icon {
  top: 5px;
  right: 5px;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/Form/FormImage/index.tsx
var FormImage = ({ field, ...props }) => {
  const { t } = props;
  const [file, setFile] = useState(
    field.isMulti ? field.getValueSelected ?? [] : field.getValueSelected ?? null
  );
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const onSelect = (data) => {
    const convertedData = data?.map((item) => ({
      id: item.id,
      download_url: item.download_url,
      name: item.name
    }));
    if (field.isMulti) {
      convertedData.length && setFile([...file, ...convertedData]);
      field.handleChange([...file, ...convertedData]);
    } else {
      convertedData.length && setFile(convertedData);
      field.handleChange(convertedData);
    }
    setShow(false);
    field?.blurred();
  };
  const deleteImage = (e, index) => {
    e.stopPropagation();
    if (field.isMulti) {
      const dataRemoved = file.filter(function(value, _index) {
        return _index !== index;
      });
      setFile(dataRemoved);
      field.handleChange([...dataRemoved]);
    } else {
      setFile(null);
      field.handleChange(null);
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, field.isMulti ? /* @__PURE__ */ React.createElement("div", { className: "position-relative field-multi-image" }, /* @__PURE__ */ React.createElement(Row, { className: "gx-24 mb-16" }, file && Array.isArray(file) && file?.map((item, key) => {
    return item ? /* @__PURE__ */ React.createElement(Col, { lg: 2, key }, /* @__PURE__ */ React.createElement(Ratio, { aspectRatio: "1x1" }, /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center w-100 h-100 border image-wrapper" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "delete-icon p-sm rounded-2",
        onClick: (e) => {
          deleteImage(e, key);
        }
      },
      /* @__PURE__ */ React.createElement(SVGComponent, { url: "/assets/images/delete.svg", className: "bg-danger" })
    ), /* @__PURE__ */ React.createElement(
      Image,
      {
        src: typeof item === "object" && item !== null ? item?.download_url : JSON.parse(item)?.download_url,
        alt: field.value,
        className: "w-100 h-100 object-fit-contain",
        wrapperClassName: "h-100"
      }
    )))) : null;
  })), /* @__PURE__ */ React.createElement(
    Button,
    {
      variant: `light`,
      className: ` px-24 py-10 fw-semibold d-flex align-items-center rounded-1 border`,
      onClick: () => {
        setShow(true);
      }
    },
    /* @__PURE__ */ React.createElement(SVGComponent, { url: "/assets/images/add-media-image.svg", className: "bg-black me-1" }),
    t("txt_add_more_photo")
  )) : /* @__PURE__ */ React.createElement("div", { className: "position-relative" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "d-flex align-items-center justify-content-center p-24 field-single-image border border-da-1 rounded-3 cursor-pointer position-relative  image-wrapper",
      onClick: () => {
        setShow(true);
      }
    },
    (!file || Array.isArray(file) && !file[0]) && /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center p-2 w-100" }, /* @__PURE__ */ React.createElement("div", { className: "text-center fs-14 text-body opacity-50 w-100" }, /* @__PURE__ */ React.createElement("p", { className: "mb-0" }, t("txt_browse_from_computer_choose_from_media_drag_file_here")))),
    file?.length ? file[0] && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "delete-icon p-sm rounded-2",
        onClick: (e) => {
          deleteImage(e, 0);
        }
      },
      /* @__PURE__ */ React.createElement(SVGComponent, { url: "/assets/images/delete.svg", className: "bg-danger" })
    ), /* @__PURE__ */ React.createElement(
      Image,
      {
        src: typeof file[0] === "object" && file[0] !== null ? file[0]?.download_url : JSON.parse(file[0])?.download_url,
        alt: field.value,
        className: "w-100 h-100 object-fit-contain",
        wrapperClassName: "h-100"
      }
    )) : null
  ), /* @__PURE__ */ React.createElement("p", { className: "my-8px fs-14 opacity-50" }, t("txt_max_file_size"))), /* @__PURE__ */ React.createElement(
    ModalDAMComponent,
    {
      show,
      onHide: handleClose,
      onSelect,
      type: "image",
      accept: {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"]
      },
      isMulti: field.isMulti
    }
  ));
};
var A = withTranslation()(FormImage);

export {
  A
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  MemberEdit_default
} from "./chunk-YH6332LR.js";
import {
  MemberViewModelContextProvider
} from "./chunk-HZ3OFIQH.js";

// src/pages/Members/edit.tsx
import React, { Component } from "react";
import { observer } from "mobx-react";
var EditMemberProvider = observer(
  class EditMemberProvider2 extends Component {
    render() {
      return /* @__PURE__ */ React.createElement(MemberViewModelContextProvider, null, /* @__PURE__ */ React.createElement(MemberEdit_default, null));
    }
  }
);

export {
  EditMemberProvider
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  MemberViewModel_default
} from "./chunk-UUCAFOTX.js";
import {
  MemberStore
} from "./chunk-EBEDYFR7.js";

// src/pages/Members/MemberViewModel/MemberViewModelContextProvider.tsx
import React from "react";
var memberStore = new MemberStore();
var memberViewModel = new MemberViewModel_default(memberStore);
var MemberViewModelContext = React.createContext({
  model: memberViewModel
});
var MemberViewModelContextProvider = ({ children }) => {
  return /* @__PURE__ */ React.createElement(MemberViewModelContext.Provider, { value: { model: memberViewModel } }, children);
};
var useMemberViewModel = () => React.useContext(MemberViewModelContext);
var withMemberViewModel = (Component) => (props) => {
  return /* @__PURE__ */ React.createElement(Component, { ...props, ...useMemberViewModel() });
};

export {
  MemberViewModelContext,
  MemberViewModelContextProvider,
  useMemberViewModel,
  withMemberViewModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  PermissionViewModel_default
} from "./chunk-MXUFCMN2.js";
import {
  PermissionStore
} from "./chunk-WJJKQGYK.js";

// src/pages/Permission/PermissionViewModel/PermissionViewModelContextProvider.tsx
import React from "react";
var permissionStore = new PermissionStore();
var permissionViewModel = new PermissionViewModel_default(permissionStore);
var PermissionViewModelContext = React.createContext({
  model: permissionViewModel
});
var PermissionViewModelContextProvider = ({ children }) => {
  return /* @__PURE__ */ React.createElement(PermissionViewModelContext.Provider, { value: { model: permissionViewModel } }, children);
};
var usePermissionViewModel = () => React.useContext(PermissionViewModelContext);
var withPermissionViewModel = (Component) => (props) => {
  return /* @__PURE__ */ React.createElement(Component, { ...props, ...usePermissionViewModel() });
};

export {
  PermissionViewModelContext,
  PermissionViewModelContextProvider,
  usePermissionViewModel,
  withPermissionViewModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

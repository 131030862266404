import {
  SVGComponent
} from "./chunk-C5UZGLWY.js";
import {
  ModalDAMComponent
} from "./chunk-25MFLZ6O.js";
import {
  ComponentVideo_default
} from "./chunk-R5DSNFA2.js";

// src/components/Form/FormVideo/index.tsx
import React, { useState } from "react";
import { Button, Col, Ratio, Row } from "react-bootstrap";

// src/components/Form/FormVideo/index.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
.field-single-image {
  width: 248px;
  height: 248px;
  outline: 0;
}

.video-wrapper:hover .delete-icon {
  display: block;
}

.delete-icon {
  display: none;
  position: absolute;
  top: 13px;
  right: 13px;
}
.delete-icon:hover {
  background-color: #ebebeb;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/Form/FormVideo/index.tsx
var FormVideo = ({ field }) => {
  const [file, setFile] = useState(field.getValueSelected);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const onSelect = (data) => {
    const convertedData = data?.map((item) => ({
      id: item.id,
      download_url: item.download_url
    }));
    convertedData.length && setFile(convertedData);
    field.handleChange(convertedData);
    setShow(false);
    field?.blurred();
  };
  const deleteImage = (e) => {
    e.stopPropagation();
    setFile(null);
    field.handleChange(null);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "position-relative" }, file?.length && file[0] && /* @__PURE__ */ React.createElement(Row, { className: "gx-24 mb-16" }, /* @__PURE__ */ React.createElement(Col, { lg: 7 }, /* @__PURE__ */ React.createElement(Ratio, { aspectRatio: "16x9" }, /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center w-100 h-100 border video-wrapper" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "delete-icon p-sm rounded-2",
      onClick: (e) => {
        deleteImage(e);
      }
    },
    /* @__PURE__ */ React.createElement(SVGComponent, { url: "/assets/images/delete.svg", className: "bg-danger" })
  ), /* @__PURE__ */ React.createElement(ComponentVideo_default, { src: file[0] && file[0]?.download_url }))))), /* @__PURE__ */ React.createElement(
    Button,
    {
      variant: `light`,
      className: ` px-24 py-10 fw-semibold d-flex align-items-center rounded-1 border`,
      onClick: () => {
        setShow(true);
      }
    },
    /* @__PURE__ */ React.createElement(SVGComponent, { url: "/assets/images/add-media-image.svg", className: "bg-black me-1" }),
    "Add More Video"
  )), /* @__PURE__ */ React.createElement(
    ModalDAMComponent,
    {
      show,
      onHide: handleClose,
      onSelect,
      type: "video",
      accept: {
        "video/*": [".mp4", ".mpeg", ".webm"]
      }
    }
  ));
};

export {
  FormVideo
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
